function updateExplanation() {
    // alert("it works");
    var a = document.getElementById("transaction_transaction_type");
    var forType = a.options[a.selectedIndex].value;

    var b = document.getElementById("transaction_from");
    var bb = document.getElementById("personLabel");
    var fromPerson = b.options[b.selectedIndex].value;

    var c = document.getElementById("transaction_list");
    var d = document.getElementById("listLabel");
    var forList = c.options[c.selectedIndex].value;

    
    var recurOptions = document.getElementById("recurringOptions")
    var recurCheckbox = document.getElementById("transaction_recurring")

    if (recurCheckbox.checked) {
        recurOptions.style.display = "block"
    } else {
        recurOptions.style.display = "none"
    }


    var fromText;
    var toText;

    if (fromPerson == 2) {
        fromText = "Tom";
        toText = "Lauren";
    } else {
        fromText = "Lauren";
        toText = "Tom";
    }

    var finalString = "";
    var ignoreList = false;

    c.style.display = "block";
    d.style.display = "block";
    if (forType == 0) {
         finalString = fromText + " paid for something ";
         bb.innerHTML = "Purchaser";
    } else if (forType == 1) {
        finalString = fromText + " received money or was refunded for something ";
        bb.innerHTML = "Refundee";
    } else if (forType == 2) {
        bb.innerHTML = "From";
        c.style.display = "none";
        d.style.display = "none";
        finalString = fromText + " paid money to " + toText;
        ignoreList = true;
    }        

    if (!ignoreList) {
        if (forList == 0) {
            finalString += "that should be split 50/50";
        } else {
            finalString += "that should be split 65/35";
        }
    }

    document.getElementById("explanation").innerHTML = finalString;
};


export default updateExplanation;